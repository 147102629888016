import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import './ContactUs.css';

function ContactUs() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <div className="p-5 mb-4 bg-light rounded-3 custom-jumbotron">
            <div className="container-fluid py-5">
              <h1 className="display-5 fw-bold">Contact Us</h1>
              <p className="fs-4">We'd love to hear from you! Reach out to us for any inquiries or support.</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={6}>
          <h2>Get in Touch</h2>
          <p>
            If you have any questions, concerns, or need assistance with our services, please don't hesitate to contact us.
            You can reach us through the form below, email, or phone. Our team is here to help you with any tech repair needs.
          </p>
          <p>
            <strong>Email:</strong> support@thehardwaremedic.com<br />
            <strong>Phone:</strong> (123) 456-7890
          </p>
        </Col>
        <Col md={6}>
          <Form>
            <Form.Group controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control type="text" placeholder="Enter your name" />
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control type="email" placeholder="Enter your email" />
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows={3} placeholder="Your message" />
            </Form.Group>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2>Our Location</h2>
          <p>
            Visit us at our store for in-person support and repairs. We are located at:
          </p>
          <p>
            123 Tech Street<br />
            Tech City, TC 12345<br />
            <strong>Opening Hours:</strong> Monday to Friday, 9 AM - 6 PM
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default ContactUs;
