import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './AboutUs.css';

function AboutUs() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <div className="p-5 mb-4 bg-light rounded-3 custom-jumbotron">
            <div className="container-fluid py-5">
              <h1 className="display-5 fw-bold">About The Hardware Medic</h1>
              <p className="fs-4">Your trusted partner in tech repair services.</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={6}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Our Mission</Card.Title>
              <Card.Text>
                Our mission is to provide high-quality, reliable, and affordable tech repair services to our customers.
                We are dedicated to ensuring customer satisfaction through our professional and efficient repair services.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Our Vision</Card.Title>
              <Card.Text>
                We aim to be the leading tech repair service provider, known for our commitment to quality, integrity, and customer care.
                Our vision is to create a seamless and hassle-free repair experience for all our customers.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2>Our Values</h2>
          <p>
            At The Hardware Medic, we believe in transparency, integrity, and dedication. Our values guide us in delivering exceptional service
            to our customers. We strive to build long-term relationships based on trust and reliability.
          </p>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Expert Technicians</Card.Title>
              <Card.Text>
                Our team consists of highly skilled and experienced technicians who are passionate about technology and repair.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Customer Focus</Card.Title>
              <Card.Text>
                We prioritize our customers and their needs, ensuring that we provide personalized and attentive service.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Quality Assurance</Card.Title>
              <Card.Text>
                We guarantee the quality of our repair services, using only the best parts and techniques to ensure lasting results.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default AboutUs;
