import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Home.css';

function Home() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <div className="p-5 mb-4 bg-light rounded-3 custom-jumbotron">
            <div className="container-fluid py-5">
              <h1 className="display-5 fw-bold">Welcome to The Hardware Medic</h1>
              <p className="fs-4">Your one-stop solution for all tech repair needs.</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Quality Repairs</Card.Title>
              <Card.Text>
                Our team of experts provides high-quality repair services for all your tech devices.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Affordable Prices</Card.Title>
              <Card.Text>
                We offer competitive pricing without compromising on the quality of our services.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Customer Support</Card.Title>
              <Card.Text>
                Our customer support team is available 24/7 to assist you with any questions or concerns.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2>Why Choose Us?</h2>
          <p>
            At The Hardware Medic, we are committed to providing the best possible service to our customers.
            Our team of experienced technicians is equipped with the latest tools and technology to ensure
            that your devices are repaired quickly and efficiently. Whether you need a screen repair, hard
            drive fixing, or any other tech service, we have got you covered.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Home;
