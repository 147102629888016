import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import './Services.css';

function Services() {
  return (
    <Container>
      <Row className="mt-5">
        <Col>
          <div className="p-5 mb-4 bg-light rounded-3 custom-jumbotron">
            <div className="container-fluid py-5">
              <h1 className="display-5 fw-bold">Our Services</h1>
              <p className="fs-4">Comprehensive tech repair services to meet all your needs.</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Hardware Repair</Card.Title>
              <Card.Text>
                We provide comprehensive hardware repair services for all types of tech devices, ensuring they function like new.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Hard Drive Fixing</Card.Title>
              <Card.Text>
                Our experts can fix and recover data from damaged hard drives, ensuring your important information is not lost.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Screen Repair</Card.Title>
              <Card.Text>
                We specialize in screen repair for smartphones, tablets, and laptops, restoring your devices to their original condition.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Battery Replacement</Card.Title>
              <Card.Text>
                We offer battery replacement services for various devices, ensuring your device's longevity and performance.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Software Installation</Card.Title>
              <Card.Text>
                Our technicians can help you with software installation and configuration, making sure your device runs smoothly.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="custom-card">
            <Card.Body>
              <Card.Title>Virus Removal</Card.Title>
              <Card.Text>
                We provide virus and malware removal services to protect your devices from harmful software and ensure your data's safety.
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <h2>Why Choose Our Services?</h2>
          <p>
            At The Hardware Medic, we are committed to providing top-notch repair services to our customers. Our team of experienced technicians
            uses the latest tools and technology to ensure that your devices are repaired quickly and efficiently. Whether you need hardware repair,
            hard drive fixing, screen repair, or any other tech service, we are here to help.
          </p>
        </Col>
      </Row>
    </Container>
  );
}

export default Services;
